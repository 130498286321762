import React from 'react'
// import {Link} from 'gatsby'
// import service4 from '../../assets/images/services/service4.png'
import service5 from '../../assets/images/services/service5.png'
import service6 from '../../assets/images/services/service6.png'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>We will come up with our Handy tools to make our customer life easy</h2>
                </div>
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">TDAAS DATA SOLUTION</span>
                            <h2>Test Data As a Service</h2>
                            <p>We saw lot of times when we have fixed data in excel sheets or hard coded data cause for many automation failures to overcome we have developed Test Data As A Service solution that works seemlesly.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Dynamic Data Mechanism</h4>
                                <p>Many customers has challenge to maintain valid data because of varios reasons to get data corrupted and once automation run data state will get change to bring that data back to the original state will be quicker and neet.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Get Data as api call</h4>
                                <p>Data required with variouse condtions our rule engine mechanism can filter data seemlesly and can be supplied as a service call for individual test conditions.</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={service5} alt="features" />
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service6} alt="features" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">BROWSER LAB</span>
                            <h2>KommForce Test Execution Lab </h2>
                            <p>ACTEL is a browser automation solution compatible with Selenium, Cypress, Playwright and Puppeteer, Simple and extremely efficient solutions
                                for your Selenium test infrastructure .</p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> KFTEL-Run in your own cloud VMs</span></li>
                                <li><span><i className='bx bx-check'></i> KFTEL-Run on your own Kubernetes or Openshift</span></li>
                                <li><span><i className='bx bx-check'></i> KommForce Solutions</span></li>
                                <li><span><i className='bx bx-check'></i> 24/7 Support</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">ACESSIBILITY SOLUTIONS</span>
                            <h2>Web/Mobile web Accessibility</h2>
                            <p>Introduces how people with disabilities use the web. Describes tools and approaches that people with different kinds of disabilities use to browse the web and the barriers they encounter due to poor design.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> KommForce Accessibiliy Framework(ACAF)</h4>
                                <p>Our Test Automation frameworks equiped with multi level Accessibility standards like Section508, WCAG2.0, WCA2AA, WCAG2AAA, with great features to implement as part of regression test suite.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Onpremise Accessibility Central (OAC)</h4>
                                <p>This solution is a web applicaton can be deployed with customer's networks wich can be used by UAT, Product teams can run Accessibility Scanners with out any coding skill, this supports both Authenticated non Authenticated pages, also provides great production monitoring.</p>
                            </div>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Accessibility Web Service</h4>
                                <p>This is a web service deployed with in customer's network which can enable dev teams and pipelines can run through this service easilty to identify issues early stage in the development to reduce cost of deffects.</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={service5} alt="features" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne